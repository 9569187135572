export class DateHelper {
    static getFormatted(dateTimeString: string | undefined, locale: string = 'de-DE'): string {
        if (!dateTimeString) return '';

        const date = new Date(dateTimeString);

        // test dateTimeString
        if (isNaN(date.getTime())) {
            throw new Error('Invalid date provided');
        }

        // test locale string
        try {
            new Intl.DateTimeFormat(locale, { dateStyle: 'short' });
        } catch (_error) {
            throw new Error('Invalid locale provided');
        }

        return new Intl.DateTimeFormat(locale, { dateStyle: 'short' }).format(date);
    }
}
